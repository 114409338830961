import React from 'react'
import { graphql } from 'gatsby'
import Releases from '../components/Releases'

import News from '../components/News'
import Show from '../components/Show'
import Merch from '../components/Merch'
import Video from '../components/Video'

import Wrapper from '../components/Wrapper'
import Img from "gatsby-image";
import PRESS_QUOTES from "../components/PressQuotes";

export const HOMEPAGE_QUERY = graphql`
query HOMEPAGE_QUERY ($yesterday: Date) {
  epkItem: allContentfulEpk(limit: 1) {
    edges {
      node {
        coverImage {
          fluid(maxWidth: 380, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }            
          file {
            url
          }
        }
      }
    }
  }
  pressQuoteItems: allContentfulPressQuotes {
    edges {
      node {
        quote {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 512)
          }
        }
        quoteBy
        quoteUrl
        quoteLinkTitle
      }
    }
  }  
  showList: allContentfulShows(filter: {start: {gte: $yesterday}}, sort: {fields: start, order: ASC}) {
    edges {
      node {
        ticketPrice
        ticketUrl
        name
        locationUrl
        locationStreetAddress
        locationPostalCode
        locationName
        end
        start
        locationCity
        description {
          childMarkdownRemark {
            excerpt(pruneLength: 512)
          }
        }
      }
    }
  }
  newsList: allContentfulNews(filter: {showOnHomePage: {eq: true}}, sort: {fields: displayDate, order: DESC}) {
    edges {
      node {
        title
        shortDescription {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 512)
          }
        }
        showOnHomePage
        displayDate
        images {
          fluid(maxWidth: 380) {
            ...GatsbyContentfulFluid_withWebp
          }
          fixed(width: 320) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        readMoreUrl
      }
    }
  }
  latestRelease: allContentfulReleases(sort: {fields: releaseDate, order: DESC}, limit: 2) {
    edges {
      node {
        title
        showTitleOnHomepage
        streamingURLs
        releaseDate
        images {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        relatedMerch {
          url
          sizes
          price
          name
          description {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 512)
            }
          }
          images {
            fixed(width: 750) {
              src
            }
          }
        }
        tracks {
          tracks
        }
      }
    }
  }
  merchItems: allContentfulMerch(sort: {fields: sortingWeight, order: ASC}) {
    edges {
      node {
        sizes
        url
        price
        name
        images {
          fluid(maxWidth: 380) {
            ...GatsbyContentfulFluid_withWebp
          }
          fixed(width: 320) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        description {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 512)
          }
        }
      }
    }
  }
  videoItems: allContentfulVideos(sort: {fields: sortingWeight, order: ASC}) {
    edges {
      node {
        title
        youtubeId
      }
    }
  }
}
`;

const Home = ({data:{epkItem, pressQuoteItems, latestRelease, newsList, showList, merchItems, videoItems, meta }}) => {
  const epk = epkItem.edges[0].node || false
  const pressQuotes = pressQuoteItems && pressQuoteItems.edges || false
  const release = latestRelease.edges[1] || false
  const breakTheFrameRelease = latestRelease.edges[0] || false
  const news = newsList.edges.length && newsList.edges || false
  const shows = showList.edges.length && showList.edges || false
  const merch = merchItems.edges || false
  const videos = videoItems.edges || false

  return (<Wrapper>

    {/* Break The Frame release */}
    { breakTheFrameRelease && <Releases releases={[breakTheFrameRelease]} showTitle={false} /> }
    <section>
      <h2>Break The Frame / Dam That River</h2>
      <p>
        We're back with our new single "Break The Frame"!
        The single includes one original track, "Break the Frame," as well as a cover of "Dam That River" by Alice In Chains.
      </p>
    </section>

    {/* Album release */}
    { release && <Releases releases={[release]} showTitle={false} /> }
    <h2>OUT NOW + IN STORES</h2>
    <section>
      <p>
        We are proud to announce that our first album is released via WormHoleDeath Records. In WHD we found a partner in which we recognize the unconditional love for music and we are excited to join them in their mission to bring fine music around the globe.
        This album is very dear to us; it is the result of our friendship, of our addiction to heavy and melodic music, and of countless hours in our rehearsal room. Now, the time has come to release it to the masses. This record is our first statement and we are ready, fired up and determined to back it up on stage, as soon as humanly possible. Anytime, anywhere. This is just the beginning!
      </p>
    </section>

    {/* Merch */}
    { merch && <section className="c-merch">
      <h2>Merch</h2>
      <ul className="c-merch__list">{
        merch.map(({ node }) => {
          return(<li key={node.name}>
            <Merch {... node} />
          </li>)
        })
      }</ul>
    </section> }

    {/* News */}
    { news && <section className="c-news">
      <h2><a href={`/news`}>News</a></h2>
      <ul className="c-news__list">{
        news.map(({ node }) => {
          return(<li key={node.name}>
            <News {... node} />
          </li>)
        })
      }</ul>
    </section> }

    {/* Shows */}
    { shows && <section className="c-shows">
      <h2>Shows</h2>
      <ol className="c-shows__list">{
        shows.map(({ node }) => {
          return(<li className="c-shows__item" key={node.name}>
            <Show {... node} />
          </li>)
        })
      }</ol>
    </section> }

    {/* Videos */}
    { videos && <section className="c-videos">
      <h2>Videos</h2>
      <ol className="c-videos__list">{
        videos.map(({ node }) => {
          return(<li className="c-videos__item" key={node.title}>
            <Video {... node} />
          </li>)
        })
      }</ol>
    </section> }

    {/* Press quotes */}
    { pressQuotes && <PRESS_QUOTES pressQuotes={pressQuotes} /> }

  </Wrapper>)
};

export default Home
